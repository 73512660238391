import {getPendingTripsBySearchQuery,getPendingTripsByTakeOffDateAndTime} from "@/services/api/APIService"
export default {
    updateTripAndTravellerDetails({ commit }, payload) {
        commit("UPDATE_TRIP_AND_TRAVELLER_DETAILS", payload);
    },
   async setPendingTrips({commit},payload){
        return await getPendingTripsBySearchQuery(payload).then(res => {
               res.data.forEach(trip =>{
                   if (trip.tripRegion === 'crossCountry'){
                       console.log("found")
                       trip.tripCategories.forEach(category => category.selected = false)
                   }
               })
                commit("SET_PENDING_TRIPS", res.data);
                return Promise.resolve()},
            error => {
                return Promise.reject(error)
            })
   },
    async setPendingTripsByDateAndTime({commit},payload){
        return await getPendingTripsByTakeOffDateAndTime(payload).then(res => {
                res.data.forEach(trip =>{
                    if (trip.tripRegion === 'crossCountry'){
                        console.log("found")
                        trip.tripCategories.forEach(category => category.selected = false)
                    }
                })
                commit("SET_PENDING_TRIPS", res.data);
                return Promise.resolve()},
            error => {
                return Promise.reject(error)
            })
    },
   setOneWayTrips({commit},payload){
        commit('SET_ONE_WAY_TRIPS',payload)
   },

   setUserSelectedTrip({commit},payload){
        commit('SET_USER_SELECTED_TRIP',payload)
   },
    setUserSelectedPaymentOption({commit},payload){
        commit('SET_USER_SELECTED_PAYMENT_OPTION',payload)
    }
};